<template>
  <Layout>
    <div class="licontainer regcontainer">
      <Header
        :title="content.o_title"
        :back="true"
        regulation="tcf20"
      />
      <h1 :dir="dir">
        {{ content.o_title }}
      </h1>
      <div
        class="text"
        :dir="dir"
        v-html="content.li_content"
      />
      <h2>{{ content.li_purposes }}</h2>
      <div
        class="list first"
        :dir="dir"
      >
        <Purpose
          v-for="(purpose, i) in screen.purposes"
          :key="i"
          :purpose="purpose"
          :vendor-details="false"
          :is-legitimate-interest="true"
        />
      </div>
      <h2>{{ content.li_vendors }}</h2>
      <div
        :class="['show_vendors', svDisplay ? '' : 'hide']"
        @touchstart="showvendors($event)"
        @click="showvendors($event)"
      >
        {{ content.a_showvendors }}
      </div>
      <Loading v-if="iabVendors === null && isLoading" />
      <div
        class="list"
        :dir="dir"
      >
        <Vendor
          v-for="(vendor, i) in vendorsBatch"
          :key="i"
          :vendor="vendor"
          :vendor-details="true"
          :is-legitimate-interest="true"
        />
        <div
          v-if="vendorsBatch !== null && showMore"
          class="list_more"
          @touchstart="showMoreVendors($event)"
          @click="showMoreVendors($event)"
        >
          {{ content.a_showmore }}
        </div>
      </div>
      <Footer screen="legitimate-interest" />
    </div>
  </Layout>
</template>

<script>
import * as Sentry from '@sentry/vue'
import Header from '@components/Header.vue'
import Vendor from '@components/tcf20/Vendor.vue'
import Footer from '@components/tcf20/Footer.vue'
import Loading from '@components/Loading.vue'

export default {
  metaInfo() {
    return {
      title: 'Legitimate Interest | Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Legitimate Interest | Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,
    Vendor,
    Footer,
    Loading,
  },
  data() {
    return {
      content: {},
      screen: {},
      showVendorsBatchSize: 10,
      totalVendorsToShow: 10,
      svDisplay: true,
      isLoading: false,
      iabVendors: null,
      startTime: null,
      endTime: null,
      showMore: true,
      dir: 'ltr',
    }
  },
  computed: {
    vendorsBatch() {
      return this.iabVendors !== null
        ? this.iabVendors.slice(0, this.totalVendorsToShow)
        : null
    },
  },
  created() {
    // get screen display data
    this.screen = this.$compliance.getScreen('legitimate-interest')
    this.content = this.$compliance.getLocalisedContent()
    // retrieve & replace screen option data from GVL
    for (var purpose in this.screen.purposes) {
      // make sure we don't try this again if we already retireved purposes & replaced them
      if (!('name' in this.screen.purposes[purpose])) {
        try {
          this.screen.purposes[purpose] = {
            ...this.screen.purposes[purpose],
            ...this.$compliance.GVL.getPurpose(
              this.screen.purposes[purpose].type,
              this.screen.purposes[purpose].id
            ),
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  mounted() {
    this.$compliance.TCF20.setCurrentConsentScreen('custom')
    this.dir = this.$compliance.dir
  },
  methods: {
    retrieveIABVendors(callback) {
      // retrieve & replace screen option data from GVL & set initial consents
      if (this.iabVendors === null) {
        // fetch vendors
        this.startTime = new Date().getTime()
        if (this.$compliance.legacyMode) {
          const v = this.$compliance.GVL.getVendors('legitimatePurpose')
          this.iabVendors = Object.values(v).map((el) => {
            return { ...el }
          })
          callback()
        } else {
          this.$compliance.delegate
            .promiseLegitimateInterestVendors()
            .then((res) => {
              this.iabVendors = Object.values(res).map((el) => {
                return { ...el }
              })
              callback()
            })
            .catch((err) => {
              this.$compliance.debug.error(err)
            })
        }
      }
    },
    showvendors(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      // hide show vendors & start loading animation
      this.svDisplay = false
      this.isLoading = true
      // retrieve & replace screen option data from GVL & set initial consents
      this.retrieveIABVendors(() => {
        var inter = setInterval(() => {
          if (this.iabVendors !== null) {
            this.endTime = new Date().getTime()
            this.$compliance.debug.log(
              'INFO',
              'Vendor list loaded in ' + (this.endTime - this.startTime) + 'ms'
            )
            clearInterval(inter)
          }
        }, 80)
        // hide loading animation
        this.isLoading = false
      })
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[TCF2.0] show vendors',
        timestamp: new Date(),
      })
    },
    showMoreVendors(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.totalVendorsToShow += this.showVendorsBatchSize
      if (this.totalVendorsToShow >= this.iabVendors.length)
        this.showMore = false
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[TCF2.0] show more vendors',
        timestamp: new Date(),
      })
    },
  },
}
</script>

<style lang="scss">
.licontainer {
  position: relative;
  background: #fff;
  padding: 10px 0 10px 0;
  width: 100%;

  .text {
    color: #4c5d6a;
    font-size: 1em;
    text-align: center;
    margin: 0;
    padding: 20px 10px 30px 10px;
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  h1 {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 70px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  h2 {
    margin: 10px 20px 0 20px;
    color: #4c5d6a;
    font-size: 1.6em;
    line-height: 1.6em;
    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2rem;
    }
  }
  .show_vendors {
    color: #688194;
    text-decoration: underline;
    text-align: center;
    padding: 20px 0 50px 0;
    font-size: 1em;
    &.hide {
      display: none;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.8rem;
    }
  }
  .list {
    background: #fff;
    padding: 0 10px;
    margin: 20px 0 130px 0;
    @media screen and (min-width: 768px) {
      margin: 20px 0 200px 0;
    }

    &.first {
      margin: 20px 0 30px 0;
    }
    h2 {
      font-size: 1.6em;
      color: #626d77;
      border-bottom: 1px solid #b8b8b8;
      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 2rem;
      }
    }
    .list_more {
      color: #688194;
      text-decoration: underline;
      font-size: 1em;
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }
  }
}
.ru .licontainer h1 {
  font-size: 1.6em;
}
</style>
